import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import CKEditor from 'ckeditor4-vue';
// 默认主题
// import 'element-ui/lib/theme-chalk/index.css';
// 自定义生成的主题
import '@/assets/style/element-#59B17A/index.css'

// 整合Ckedit富文本
import Ckedit from "@/components/CkeditCom.vue"
Vue.component('Ckedit', Ckedit)
 
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor)

// 工具js
import baseTools from './utils/baseTools'


Vue.prototype.$base = baseTools;
Vue.prototype.$bus = new Vue();

Vue.use(ElementUI);
// Vue.use(CKEditor);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
