<template>
  <div class="bottom">
    <span class="copyright">版权所有（c）中国检验检疫科学研究院 备案号：<a href="https://beian.miit.gov.cn" class="link"
        target="_blank">浙ICP备2023012803号</a></span>
    <span class="link-con">
      <span>站点导航：</span>
      <span>人工智能算法训练迭代平台</span>
      <span>分布式快速鉴定平台</span>
      <span>入侵物种多模态鉴定客户端</span>
    </span>
  </div>
</template>
<script>

export default {
  name: 'Bottom'
}
</script>
<style scoped lang="less">
.bottom {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #C3C8D8;
  padding: 0 40px;

  .link {
    text-decoration: none;
    color: @themeColorGray;
  }

  .link-con {
    span {
      font-size: 12px;
      color: @themeColorGray;
    }

    span:not(:first-child) {
      cursor: pointer;
    }

    span:nth-child(3) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}
</style>