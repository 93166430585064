<template>
  <div class="code-input-item">
    <div>
      <span class="title">{{ title }}</span>
      <!-- <span class="error">{{ error }}</span> -->
      <p class="input-p" :class="{ 'error-input': error }">

        <el-input v-model="msg" :placeholder="placeholder" @input="updateMsg"
          :name="prop + new Date().getTime()"></el-input>
        <span class="text" :class="{ 'cutdown-text': text.indexOf('s') > -1 }" @click="getCode">{{ text }}</span>
      </p>
    </div>

    <p><span class="error">{{ error }}</span></p>
  </div>
</template>
<script>
import {
  getSmsCode,
} from "@/api/UserHttp.js"
export default {
  name: 'CodeInput2',
  props: {
    prop: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "请输入"
    },
    phone: {
      type: String,
      defatult: "",
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  components: {

  },
  data() {
    return {
      msg: "",
      text: "获取验证码",
      timer: null,
      second: 60,
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
    this.msg = "";
    this.updateMsg()
  },
  methods: {
    updateMsg() {
      this.$emit("updateData", { prop: this.prop, data: this.msg });
    },
    getCode() {
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;  //11位手机号码正则
      if (!this.phone || !this.phone.replace(/\s+/, '')) {
        this.$emit("getCodeHandle", "手机号不能为空!")
        return
      }
      else if (!reg_tel.test(this.phone)) {
        this.$emit("getCodeHandle", "请填写正确手机号!")
        return
      }
      this.getPhoneCode();
      /* this.text = this.second + 's';
      this.timer = setInterval(() => {


        this.text = --this.second + 's';
        if (this.second === 0) {
          this.text = "重新获取验证码";
          this.second = 60;
          if (this.timer) clearInterval(this.timer);
        }

      }, 1000); */
    },
    // 获取验证码
    getPhoneCode() {

      let params = {
        phone_number: this.phone,
        type: this.type
      }
      getSmsCode(params).then(res => {
        if (res && res.code === '0' && res.message === 'OK') {
          this.$message.success("发送成功！");
          this.$emit("getCodeHandle", "")
          this.text = this.second + 's';
          this.timer = setInterval(() => {


            this.text = --this.second + 's';
            if (this.second === 0) {
              this.text = "重新获取验证码";
              this.second = 60;
              if (this.timer) clearInterval(this.timer);
            }

          }, 1000);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.code-input-item {
  margin-bottom: 15px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;

    .title {
      color: @themeColorBlack;
      font-weight: 400;
      font-size: 18px;
      width: 110px;
      flex-shrink: 0;
      flex-grow: 0;
    }



    .input-p {
      width: 320px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid @themeColorGray;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.error-input {
        border-color: @themeColorRed;
      }

      .text {
        width: 134px;
        line-height: 44px;
        // margin-right: 10px;
        color: @themeColor;
        cursor: pointer;
        text-align: center;
      }

      .cutdown-text {
        color: @themeColorGray;
      }
    }
  }

  &>p {
    margin-top: 10px;
  }

  .error {
    font-size: 16px;
    color: @themeColorRed;
    margin-left: 120px;
  }


  /deep/ .el-input {


    width: 186px;
    height: 100%;

    .el-input__inner {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
</style>