<template>
  <div id="ckedit">
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
    <button v-show="false" @click="onClick">查看编辑器内容源码</button>
  </div>
</template>

<script>
import Editor from "ckeditor5-lvjiajia-zsdl-v2/build/ckeditor";
// import MyUploadAdapter from './MyUploadAdapter';
//定义上传适配器
// function MyCustomUploadAdapterPlugin( editor ) {
//   editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
//     // 第二个参数设置上传图片的地址
//     return new MyUploadAdapter( loader, process.env.VUE_APP_BASE_API + "?action=custom_file_upload" );
//   };
// };
export default {
  name: "Ckedit",
  props: ['value'],
  data() {
    return {
      // ckeditor配置开始 -------------------------------------------------
      editor: Editor,
      editorData: this.value, // 将父组件传递过来的值作为初始值
      editorConfig: {
        extraPlugins: [
          // MyCustomUploadAdapterPlugin  // 添加上传适配器
        ],
        placeholder: "输入正文",
        // 编辑器的配置
        /* toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            // "fontFamily", // 字体样式
            // "fontSize", // 字体大小
            // {
            //   label: '列表',
            //   icon:
            //     '<svg t="1678948802831" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1010" width="200" height="200"><path d="M132.608 204.288m-66.56 0a66.56 66.56 0 1 0 133.12 0 66.56 66.56 0 1 0-133.12 0Z" p-id="1011"></path><path d="M962.01728 158.80192l-680.68352 0.3584-0.04096 84.44928 680.7552-0.3584-0.03072-84.44928z" p-id="1012"></path><path d="M132.608 512m-66.56 0a66.56 66.56 0 1 0 133.12 0 66.56 66.56 0 1 0-133.12 0Z" p-id="1013"></path><path d="M281.33376 466.87232l-0.04096 84.44928 680.7552-0.3584-0.03072-84.44928-680.68352 0.3584z" p-id="1014"></path><path d="M132.608 819.712m-66.56 0a66.56 66.56 0 1 0 133.12 0 66.56 66.56 0 1 0-133.12 0Z" p-id="1015"></path><path d="M281.33376 775.59808l-0.04096 84.44928 680.7552-0.3584-0.03072-84.44928-680.68352 0.3584z" p-id="1016"></path></svg>',
            //   items: ['bulletedList', 'numberedList']
            // },
            "bulletedList",
            "numberedList",
            "horizontalLine",
            "blockQuote",
            "alignment",
            "todoList",
            "findAndReplace",
            {
              label: "更多",
              icon: false,
              items: [
                "italic",
                "strikethrough",
                "underline",
                "superscript",
                "subscript",
                "indent",
                "outdent",
                // 'code',
                "codeBlock",
                "removeFormat",
              ],
            },
            "|",
            "fontBackgroundColor",
            "fontColor",
            "highlight",
            "|",
            "imageUpload",
            // 'mediaEmbed',
            "link",
            "specialCharacters",
            "insertTable",
            "|",
            "htmlEmbed",
            "sourceEditing",
            // "pageBreak", // 分页符

            // More of toolbar's config.
            // ...
          ],
          shouldNotGroupWhenFull: true,
        }, */
        // 标题的配置
        heading: {
          options: [
            {
              model: "paragraph",
              title: "正文",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "H1 大标题",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "H2 中标题",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "H3 小标题",
              class: "ck-heading_heading3",
            },
          ],
        },
        // 缩进的配置
        indentBlock: {
          offset: 2,
          unit: "em",
        },
        // html映射
        htmlEmbed: {
          showPreviews: true,
        },
        // 启用所有HTML功能
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        // 图像
        image: {
          toolbar: ["toggleImageCaption", "|", "imageStyle:aligncenter"],
        },
      },
    };
  },
  watch: {
    value(newValue) {
      // 监听父组件传递的值的变化，更新子组件中的编辑器内容
      this.editorData = newValue;
    },
    editorData(newData) {
      // 监听编辑器内容的变化，将变化的内容通过事件传递给父组件
      this.$emit('input', newData);
    }
  },
  methods: {
    onClick() {
      this.neirong = this.editorData;
      console.log(this.neirong, "这是内容-----");
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
}

#ckedit {
  width: 100%;
  margin: 0 auto;
  background-color: #f1f3f4;
}

// 工具栏
.ck-toolbar,
.ck-toolbar_grouping {
  // margin-top: 72px !important;
  // position: fixed !important;
  // top: 72px !important;
  width: 100% !important;
  // height: 72px !important;
  // border-bottom: 1px solid #ccced1 !important;
  box-shadow: none !important;
  border: none !important;
  // background-color: #f1f3f4 !important;
  // background-color: pink !important;
  z-index: 99 !important;
}

.ck-toolbar__items {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  // margin-left: 100px !important;
}

// 列表的两个图标禁止点击
.ck-splitbutton__action {
  pointer-events: none !important;
}

// 编辑区域
.ck-sticky-panel__content {
  width: 100% !important;
  //position: fixed !important;
  // top: 72px !important;
  z-index: 999 !important;
}

.classone {
  .ck-editor__editable_inline {
    margin-top: 208px !important;
  }
}

// .classtwo {
//   margin-top: 176px !important;
// }

.ck-editor__main {
  padding-top: 0px !important;
}

.ck-editor,
.ck-blurred,
.ck-editor__editable_inline {
  width: 100%;
  // margin-top: 176px;
  // 208px 176px
  //margin-left: 266px;
  min-height: 640px !important;
  // padding: 20px 72px !important;
  border: #4a4a4a 1px solid !important;
  box-shadow: none !important;

  // z-index: -100 !important;

  // border: 1px solid green;
  // background-color: pink !important;
  /* border: 1px solid #ffffff !important; */
  .ck-content {
    padding: 0 0px !important;
  }

  // 编辑器的视频的宽度
  video {
    width: 550px !important;
    height: 300px !important;
  }

  // ckeditor
  h1 {
    margin: 24px 0 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #4a4a4a !important;
    line-height: 1.75 !important;
    padding: 0 !important;
  }

  h2 {
    margin: 24px 0 !important;
    font-size: 19px !important;
    font-weight: 700 !important;
    color: #4a4a4a !important;
    line-height: 1.75 !important;
    padding: 0 !important;
  }

  h3 {
    margin: 24px 0 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #4a4a4a !important;
    line-height: 1.75 !important;
    padding: 0 !important;
  }

  p {
    margin: 24px 0 !important;
    font-size: 17px !important;
    color: #4a4a4a !important;
    line-height: 1.75 !important;
    padding: 0 !important;
  }

  figure {
    margin: 24px auto !important;
  }

  blockquote {
    margin: 24px 0 !important;

    h1,
    h2,
    h3,
    p {
      margin: 3px 0 !important;
      color: #999999 !important;
      font-style: normal;
    }
  }

  tbody,
  .table {
    margin: 24px 0 !important;
    max-width: 628px !important;
    color: #4a4a4a !important;
    // margin: 0 auto !important;
  }

  // code {
  //   margin: 24px 0 !important;
  // }

  // 引用的效果
  pre {
    margin: 24px 0 !important;
    -webkit-text-stroke-width: 0px;
    background-color: rgb(251, 251, 251);
    border-color: rgb(221, 221, 221);
    color: rgb(51, 51, 51);
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 4px;
    border-right-width: 1px;
    border-image: initial;
    border-radius: 1px;
    border-style: solid;
    font-size: 17px;
    font-weight: normal;
    letter-spacing: normal;
    margin: 3px 0px 5px;
    padding: 7px 18px;
    orphans: 2;
    overflow-wrap: normal !important;
    overflow: auto;
    text-align: left;
    text-decoration-color: initial;
    text-decoration-style: initial;
    text-indent: 0px;
    text-transform: none;
    white-space: pre !important;
    widows: 2;
    word-break: normal !important;
    word-spacing: 0px;
  }
}

.ck-content {

  ul,
  ol {
    font-size: 17px !important;
  }

  li {
    color: #4a4a4a;
    margin: 8px 0 8px 25px !important;
  }
}

// 添加废话，测试jenkins上传代码
.ck-content .todo-list .todo-list__label>input[checked]:before {
  background: #d1d1d1;
  border-color: #d1d1d1;
}

.ck-content .todo-list .todo-list__label>input:before {
  border-color: #d1d1d1;
}

// 源代码的展示
.ck-source-editing-area {
  margin-top: 40px !important;
  z-index: 99999 !important;
}

// 提升message提示消息的层级，避免被顶部导航栏遮住
.messageIndex {
  z-index: 99999 !important;
}
</style>